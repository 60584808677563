import { Translation } from "./types";

export const ru: Translation = {
  profile: {
    label: "Профиль",
    name: "Имя",
    email: "Email",
    role: "Роль",
    tags: "Теги",
    language: "Язык",
    theme: "Тема",
    clipboard_mode: "Режим буфера обмена",
  },
  log_groups: {
    label: "Группы логов",
    general: "Общие",
    form: {
      actions: {
        create: "Создать группу логов",
        edit: "Редактировать группу логов",
      },
      name: {
        label: "Название",
        hint: "Название - используется в качестве текстового идентификатора для группы. Должно быть указано латиницей, в нижнем регистре, и с подчеркиваниями в качестве разделителей",
      },
      title: {
        label: "Заголовок",
        hint: "Заголовок - используется для человекочитаемого названия группы в веб-интерфейсе. Поиск групп также будет осуществляться на основе этого параметра",
      },
      description: {
        label: "Описание",
        hint: "Описание - используется для человекочитаемого описания деталей группы в веб-интерфейсе. Поиск групп также будет осуществляться на основе этого параметра",
      },
      tag: {
        label: "Выбрать тег",
      },
      retention_days: {
        label: "Дни хранения",
        hint: "Дни хранения - количество дней, в течение которых логи будут доступны после их создания. По истечении указанного количества дней логи будут удалены. Чтобы установить бесконечный срок хранения, укажите 0 или оставьте поле пустым",
      },
      group_query_fields: {
        label: "Поля запроса группы",
        hint: "Поля запроса группы - набор полей, по которым будет осуществляться группировка логов",
        fields: {
          title: {
            label: "Заголовок",
            hint: "Заголовок - используется для человекочитаемого названия поля в веб-интерфейсе для фильтрации логов",
          },
          name: {
            label: "Название",
            hint: "Название - используется в качестве текстового идентификатора для группы. Должно быть указано латиницей, в нижнем регистре, и с подчеркиваниями в качестве разделителей",
          },
        },
      },
      kinds: {
        label: "Виды",
        hint: "Виды - набор вариантов выбора, по которым будет осуществляться группировка логов",
        fields: {
          title: {
            label: "Заголовок",
            hint: "Заголовок - используется для человекочитаемого названия вида в веб-интерфейсе для фильтрации логов",
          },
          name: {
            label: "Название",
            hint: "Название - используется в качестве текстового идентификатора для вида. Должно быть указано латиницей, в нижнем регистре, и с подчеркиваниями в качестве разделителей",
          },
        },
      },
    },
    alerts: {
      delete: "Вы уверены, что хотите удалить эту группу логов?",
    },
    filters: {
      query: {
        label: "Фильтры",
        kind: "Типы",
      },
      time: {
        label: "Время",
        from: "От",
        to: "До",
      },
      level: {
        label: "Уровень",
      },
    },
  },
  buttons: {
    create: "Создать",
    settings: "Настройки",
    save: "Сохранить",
    delete: "Удалить",
    edit: "Редактировать",
    build_insight: "Построить инсайт",
    open: "Открыть",
    log_in: "Войти",
    register: "Зарегистрироваться",
    previous: "Предыдущая",
    next: "Следующая",
    clear: "Очистить",
  },
  auth: {
    email: "Email",
    password: "Пароль",
  },
  validations: {
    required: "Обязательное поле",
    invalid_email: "Неверный email",
    passwords_dont_match: "Пароли должны совпадать",
  },
  members: {
    label: "Пользователи",
    form: {
      actions: {
        create: "Создать пользователя",
        edit: "Редактировать пользователя",
      },
      name: "Имя",
      email: "Email",
      role: "Выберите роль",
      tag: {
        label: "Выбрать теги",
        hint: "Нажмите, чтобы выбрать теги",
      },
      password: "Пароль",
      confirm_password: "Подтвердите пароль",
      revoked: "Отозван",
    },
    alerts: {
      delete: "Вы уверены, что хотите удалить этого пользователя?",
    },
  },
  tags: {
    label: "Теги",
    form: {
      actions: {
        create: "Создать тег",
        edit: "Редактировать тег",
      },
      name: {
        label: "Название",
        hint: "Название - используется для человекочитаемого названия тега в веб-интерфейсе",
      },
      view_order: {
        label: "Порядок просмотра",
        hint: "Порядок просмотра - определяет приоритетный порядок тегов. Теги с меньшими значениями будут отображаться выше в списке групп логов",
      },
    },
    alerts: {
      delete: "Вы уверены, что хотите удалить этот тег?",
    },
  },
  actions: {
    label: "Кастомные действия",
    form: {
      actions: {
        create: "Создать кастомное действие",
        edit: "Редактировать кастомное действие",
      },
      name: {
        label: "Название",
        hint: "Название - используется для человекочитаемого названия кастомного действия в веб-интерфейсе",
      },
      pattern: {
        label: "Паттерн",
        hint: "Паттерн - шаблон ссылки, которая будет открыта пользователем при клике на кнопку действия. Внутри паттерна можно использовать шаблонные переменные",
        variables: "Можно использовать следующие переменные в паттерне",
      },
      method: {
        label: "Метод",
        hint: "Метод - метод запроса при клике на кнопку действия",
      },
      conditions: {
        label: "Условия",
        hint: "Условия - список условий, по которым определяется необходимо ли показывать кнопку действия в конкретной лог группе",
        fields: {
          attribute: {
            label: "Атрибут",
            hint: "Атрибут - атрибут лог группы, на основе которого выполняется условие",
          },
          operation: {
            label: "Операция",
            hint: "Операция – какую операцию необходимо провести над атрибутом",
          },
          value: {
            label: "Значение",
            hint: "Значение – значение, которое сравнивается с атрибутом по заданной операции",
          },
        },
      },
      schema_name: {
        label: "Схема",
        hint: "Схема - схема, для которой необходимо отображать кнопку действия",
      },
      disabled: {
        label: "Отключен",
        hint: "Отключен - перманентно отключает отображение кнопки действия вне зависимости от условий",
      },
    },
    alerts: {
      delete: "Вы уверены, что хотите удалить это кастомное действие?",
    },
  },
  api_tokens: {
    label: "API токены",
    form: {
      actions: {
        create: "Создать API токен",
        edit: "Редактировать API токен",
      },
      name: {
        label: "Название",
        hint: "Название - используется для указания того, какой сервис будет использовать этот API токен. Это не влияет на функциональность токена",
      },
      revoked: "Отозван",
      creation_hint:
        "Ваш токен для интеграции API успешно создан. Убедитесь, что сохраните его надежно сейчас, так как он не будет отображаться снова по соображениям безопасности",
    },
    alerts: {
      delete: "Вы уверены, что хотите удалить этот API токен?",
    },
  },
  insights: {
    label: "Инсайты",
    form: {
      field_name: "Название поля",
      field_value: "Значение поля",
    },
  },
  tables: {
    log_groups: {
      time: "Время",
      level: "Уровень",
      text: "Текст",
      request: "Запрос",
      response: "Ответ",
    },
    members: {
      email: "Email",
      name: "Имя",
      role: "Роль",
      revoked: "Отозван",
      actions: "Действия",
    },
    tags: {
      name: "Название",
      view_order: "Порядок просмотра",
      actions: "Действия",
    },
    api_tokens: {
      name: "Название",
      token: "Токен",
      revoked: "Отозван",
      actions: "Действия",
    },
    actions: {
      name: "Название",
      pattern: "Паттерн",
      method: "Метод",
      conditions: "Условия",
      schema_name: "Название схемы",
      disabled: "Отключен",
      actions: "Действия",
    },
    alerting_rules: {
      name: "Название",
      enabled: "Активен",
      severity: "Важность",
      interval: "Интервал",
      threshold: "Порог",
      actions: "Действия",
    },
    notification_profiles: {
      name: "Название",
      description: "Описание",
      enabled: "Активен",
      actions: "Действия",
    },
  },
  pagination: {
    first_page: "1",
  },
  components: {
    search: {
      text: "Поиск",
    },
    sidebar: {
      open: "Открыть боковую панель",
    },
  },
  miscellaneous: {
    loading: "Загрузка...",
    brand: "Moonlogs",
    blank_option: "—",
    copied_to_clipboard: "Скопировано!",
    not_found: "Запрашиваемый ресурс не найден",
    forbidden: "У вас нет доступа к этому ресурсу",
    to_home: "Перейти на главную страницу",
    empty_search_result: "По вашему запросу логи не найдены. Пожалуйста, cкорректируйте фильтры или попробуйте еще раз позже",
  },
  alerting_rules: {
    label: "Правила алертинга",
    form: {
      actions: {
        create: "Создать правило алертинга",
        edit: "Изменить правило алертинга",
      },
      alert: "Условия алерта",
      name: {
        label: "Название",
        hint: "Уникальное название алерта",
      },
      description: {
        label: "Описание",
        hint: "Краткое описание назначения данного алерта",
      },
      enabled: {
        label: "Активен",
        hint: "Включить или отключить алерт",
      },
      severity: {
        label: "Важность",
        hint: "Определяет приоритет алерта",
      },
      interval: {
        label: "Интервал",
        hint: "Частота проверки условий алерта. Указывается в формате продолжительности, например: '30s' (30 секунд), '5m' (5 минут), '1h' (1 час)",
      },
      condition: {
        label: "Условие",
        hint: "Логическое выражение, определяющее срабатывание алерта",
      },
      threshold: {
        label: "Порог",
        hint: "Значение, с которым сравниваются фактические показатели алерта",
      },
      filters: {
        label: "Фильтры",
        hint: "Дополнительные параметры для уточнения условий алерта",
        level: {
          label: "Уровень",
          hint: "Фильтр по уровню логов",
        },
        schema_name: {
          label: "Схемы",
          hint: "Фильтр по схемам логов. Оставьте пустым, если необходимо анализировать все схемы логов",
        },
        schema_fields: {
          label: "Поля схемы",
          hint: "Фильтр по полям схем. Проверяет исключительно наличие определенного поля в схеме, вне зависимости от его значения",
        },
        schema_kinds: {
          label: "Типы схемы",
          hint: "Фильтр по типам схем. Проверяет исключительно наличие определенных типов в схемам. Оставьте пустым, если необходимо анализировать все типы схем логов",
        },
      },
      aggregation: {
        label: "Агрегация",
        hint: "Настройки группировки и подсчета событий",
        type: {
          label: "Тип",
          hint: "Тип агрегации данных",
        },
        group_by: {
          label: "Группировать по",
          hint: "Определите поля, по которым будут группироваться события",
        },
        time_window: {
          label: "Временное окно",
          hint: "Укажите период времени для агрегации данных. Указывается в формате продолжительности, например: '30s' (30 секунд), '5m' (5 минут), '1h' (1 час)",
        },
      },
    },
    alerts: {
      delete: "Вы уверены, что хотите удалить это правило алертинга?",
    },
  },
  notification_profiles: {
    label: "Алерт-менеджер",
    form: {
      actions: {
        create: "Создать профиль уведомлений",
        edit: "Изменить профиль уведомлений",
      },
      name: {
        label: "Название",
        hint: "Уникальное название профиля уведомлений",
      },
      description: {
        label: "Описание",
        hint: "Краткое описание, чтобы понимать, для чего используется этот профиль",
      },
      rule_name: {
        label: "Правила алертинга",
        hint: "Выберите правила алертинга, к которым привязан этот профиль",
      },
      enabled: {
        label: "Активен",
        hint: "Включить или отключить профиль уведомлений",
      },
      silence_for: {
        label: "Заглушить на",
        hint: "Время, в течение которого уведомления по данному профилю не будут отправляться, если профиль уже сработал (например, '5m', '1h')",
      },
      url: {
        label: "Роут",
        hint: "URL-адрес, на который будет отправляться уведомление",
      },
      method: {
        label: "Метод",
        hint: "HTTP-метод запроса",
      },
      headers: {
        label: "Заголовки",
        hint: "Дополнительные заголовки, которые будут отправлены в HTTP-запросе",
        fields: {
          key: {
            label: "Ключ",
            hint: "Название заголовка (например, 'Authorization')",
          },
          value: {
            label: "Значение",
            hint: "Значение заголовка (например, 'Bearer token')",
          },
        },
      },
      payload: {
        label: "Тело запроса",
        hint: "Формат тела запроса, который будет отправлен в уведомлении",
        variables: "Можно использовать следующие переменные в теле запроса",
      },
    },
    alerts: {
      delete: "Вы уверены, что хотите удалить этот профиль уведомлений?",
    },
  },
};
